import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Switch,
    makeStyles
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    filterMenu: {
        maxWidth: 200,
        minWidth: 150
    }
}));

const FilterControl = ({
    filterKey,
    value,
    type,
    options,
    optionsLabel,
    onChange,
    enabled
}) => {
    const classes = useStyles();
    if (type === 'switch') {
        const [
            { value: offValue, label: offLabel } = {},
            { value: onValue, label: onLabel } = {}
        ] = options || [{}, {}];
        return (
            <FormControlLabel
                control={
                    <Switch
                        data-cy="update-value-global"
                        color="primary"
                        disabled={!enabled || [undefined, null].includes(value)}
                        checked={String(value) === onValue}
                        onChange={(e, checked) =>
                            onChange(checked ? onValue : offValue, filterKey)
                        }
                    />
                }
                label={String(value) === onValue ? onLabel : offLabel}
            />
        );
    }

    if (type === 'select') {
        return (
            <FormControl
                disabled={!enabled || [undefined, null].includes(value)}
            >
                <InputLabel>{optionsLabel} </InputLabel>
                <Select
                    data-cy="dropdown-publication"
                    value={value}
                    autoWidth={true}
                    className={classes.filterMenu}
                    onChange={({ target: { value } }) =>
                        onChange(value, filterKey)
                    }
                >
                    {[...options].map((option) => {
                        return (
                            <MenuItem
                                data-cy={`${filterKey}-menu-${option.value}`}
                                value={option.value}
                                key={`${filterKey}-menu-${option.value}`}
                            >
                                {option.label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        );
    }

    return null;
};

export default FilterControl;
