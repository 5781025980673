import { Button, makeStyles, TextField } from '@material-ui/core';
import React from 'react';
import { useObjectState } from '../../../utilities/customHooks';
import { postRequest } from '../../../helpers/apiRequestUtility';
import { useSnackbar } from 'notistack';
import DialogBox from '../../../components/DialogBox/DialogBox';
import LinearProgress from '@material-ui/core/LinearProgress';

const customStyle = makeStyles(() => ({
    url: {
        '& textarea': {
            minHeight: 120,
        },
    },
}));

export default function RevalidateURL({ active }) {
    const [state, setState] = useObjectState({
        urls: '',
        processing: false,
    });

    const { enqueueSnackbar } = useSnackbar();

    const classes = customStyle();
    if (!active) return null;

    const handleRevalidate = (event) => {
        setState({ processing: true });
        const urlArray = state.urls.split(/\r?\n|\r|\n/g);

        const data = new FormData();
        data.append('urls', urlArray);

        postRequest(
            '/frontend-urls/revalidate',
            data,
            (json) => {
                enqueueSnackbar('Revalidation process started..', {
                    variant: 'success',
                });
                setState({ processing: false });
            },
            () => {
                enqueueSnackbar('Revalidation process failed..', {
                    variant: 'error',
                });
                setState({ processing: false });
            }
        );
    };

    const handleClear = (event) => {
        setState({ urls: '' });
    };

    const handleUrls = ({ target: { value } }) => setState({ urls: value });

    return (
        <div className="w-full h-auto py-6 px-8 bg-white shadow-[0_2px_15px_0_rgba(0,0,0,0.05)] flex flex-col gap-12">
            <h6 className="text-xl text-[#424242]">Revalidate URL</h6>
            <div className="w-full flex items-center justify-center">
                <div className="w-[600px] flex flex-col gap-6 pb-8">
                    <TextField
                        multiline
                        fullWidth
                        variant="outlined"
                        placeholder="Revalidate URL"
                        className={classes.url}
                        value={state.urls}
                        onChange={handleUrls}
                    />
                    <div className="grid grid-cols-2 gap-4 w-full">
                        <Button
                            variant="outlined"
                            size="large"
                            color="error"
                            onClick={handleClear}
                        >
                            Clear
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={handleRevalidate}
                        >
                            Revalidate
                        </Button>
                    </div>
                </div>
            </div>
            {state.processing && (
                <DialogBox
                    open={state.processing}
                    title="Starting revalidation..."
                    actions=""
                >
                    <LinearProgress />
                </DialogBox>
            )}
        </div>
    );
}
