import React, { Component } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import SearchBasedDropdown from '../SearchBasedDropdown/SearchBasedDropdown';
import Tooltip from '@material-ui/core/Tooltip';
import DateFilter from '../DateFilter/DateFilter';
import MultiSelect from '@khanacademy/react-multi-select';
import { withState, withProps } from './ResultsTableProvider';
import { withStyles } from '@material-ui/core';
import ResultsTableInputColumnFilter from './ResultsTableInputColumnFilter';

const styles = (theme) => ({
    actions: {
        minWidth: '136px',
    },
    noFilter: {
        minWidth: '60px !important',
    },
    applyFilter: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',

        '& td': {
            borderBottom: 'none',
        },
    },
    filterSelect: {
        paddingRight: theme.spacing(2),
    },
    dropdownSearch: {
        paddingRight: theme.spacing(2),
    },
    dateFilter: {
        paddingRight: theme.spacing(2),
    },
    filterText: {
        paddingRight: theme.spacing(2),
    },
    alterPaper: {
        padding: theme.spacing(2),
    },
});

const usedProps = [
    'isLoadingData',
    'selectable',
    'columns',
    'onColumnFilterClose',
    'onColumnFiltersApply',
];
class ResultsTableColumnFilters extends Component {
    state = {};

    handleApplyAllColumnFilters = () => {
        const { onColumnFiltersApply } = this.props;
        !!onColumnFiltersApply && onColumnFiltersApply(this.state);
    };

    handleColumnFilterClose = () => {
        const { handleStateChanged: setState, onColumnFilterClose } =
            this.props;
        setState({ showColumnFilters: false }, onColumnFilterClose);
        const reset = { ...this.state };
        //eslint-disable-next-line array-callback-return
        Object.keys(reset).map((key) => {
            reset[key] = null;
        });
        this.setState(reset);
    };

    handleOnEnter = (e) => {
        const { onColumnFiltersApply } = this.props;
        if (e.keyCode === 13)
            !!onColumnFiltersApply && onColumnFiltersApply(this.state);
    };

    renderFilters = () => {
        const { columns } = this.props;

        if (!columns || !Array.isArray(columns) || columns.length === 0)
            return null;

        return columns.map(this.renderColumnFilter);
    };

    handleSelectChange = (key, callback) => (value) => {
        this.setState({ [key]: value });
        if (callback) callback(value);
    };

    handleTextInputChange = (key, callback) => (e) => {
        this.setState({ [key]: e.target.value });
        if (callback) callback(e);
    };

    handleNumberRangeSubmit = (key) => (value) => {
        this.setState({ [key]: value });
    };

    handleExtendedFilterChange = (key, callback) => (value) => {
        this.setState({ [key]: value });
        if (callback) callback(value);
    };

    handleDateChange = (key, callback) => (value) => {
        this.setState({ [key]: value });
        if (callback) callback(value);
    };

    renderColumnFilter = (column, index) => {
        const { classes } = this.props;
        const { key, filter } = column;

        const {
            value,
            onChange = () => {},
            handler = () => {},
            isDisabled,
            isSearchable,
            type = column.type,
            options = [],
            optionValue = 'value',
            optionKey = 'label',
            isLoading = false,
            isMultiSelect,
            id = column.id,
            alterValue,
            ...rest
        } = filter || {};
        if (key === 'location') {
            var countries = [],
                cities = [],
                sortedOptions = [];
            options.map((val) => {
                if (val.url_alias.match('/')) return cities.push(val);
                else return countries.push(val);
            });
            sortedOptions = [...countries, ...cities];
            Object.assign(options, sortedOptions);
        }

        if (!filter || typeof filter !== 'object')
            return (
                <TableCell
                    className={classes.noFilter}
                    key={`column-filters-${index}`}
                />
            );

        if (type === 'select') {
            return (
                <TableCell key={`column-filters-${index}`}>
                    <SearchBasedDropdown
                        arrayOptions={Array.isArray(options) ? options : []}
                        valueKey={optionValue || 'value'}
                        labelKey={optionKey || 'label'}
                        value={value || this.state[key] || undefined}
                        handleChange={this.handleSelectChange(key, onChange)}
                        isDisabled={isDisabled || isLoading}
                        isSearchable={isSearchable}
                        isMultiSelect={isMultiSelect}
                        placeholder={isLoading ? 'Loading ...' : 'Search...'}
                        {...rest}
                    />
                </TableCell>
            );
        }

        if (['text', 'number'].includes(type)) {
            return (
                <TableCell
                    key={`column-filters-${index}`}
                    style={{ position: 'relative' }}
                >
                    <ResultsTableInputColumnFilter
                        onEnter={this.handleOnEnter}
                        onTextInputChange={this.handleTextInputChange(
                            key,
                            onChange
                        )}
                        onExtendedFilterChange={this.handleExtendedFilterChange(
                            key,
                            onChange
                        )}
                        type={type}
                        isDisabled={isDisabled}
                        value={value || this.state[key] || ''}
                        data-cy={id}
                        alterValue={alterValue}
                    />
                </TableCell>
            );
        }

        if (type === 'date') {
            return (
                <TableCell key={`column-filters-${index}`}>
                    <DateFilter
                        id={id}
                        onChange={this.handleDateChange(key, onChange)}
                        isDisabled={isDisabled}
                        value={value || this.state[key] || undefined}
                        alterValue={alterValue}
                    />
                </TableCell>
            );
        }

        if (type === 'multiselect') {
            return (
                <TableCell
                    key={`column-filters-${index}`}
                    style={{ minWidth: 250 }}
                >
                    <MultiSelect
                        options={options.map((option) => {
                            return { value: option.id, label: option.name };
                        })}
                        selected={value || this.state[key] || []}
                        onSelectedChanged={this.handleSelectChange(
                            key,
                            handler
                        )}
                        hasSelectAll={false}
                        disabled={isDisabled || isLoading}
                        isLoading={isLoading}
                        overrideStrings={{
                            selectSomeItems: isLoading
                                ? 'Loading ...'
                                : 'Search...',
                            allItemsAreSelected: 'All Items are Selected',
                        }}
                    />
                </TableCell>
            );
        }

        return (
            <TableCell
                className={classes.noFilter}
                key={`column-filters-${index}`}
            />
        );
    };

    render() {
        const {
            isLoadingData,
            selectable = true,
            showColumnFilters,
            classes,
        } = this.props;

        if (!showColumnFilters) return null;

        return (
            <TableRow data-cy="column-filters-row">
                {selectable && <TableCell padding="none" />}
                {this.renderFilters()}
                <TableCell className={classes.actions}>
                    <Tooltip title="Apply Column Filters" placement="top">
                        <IconButton
                            data-cy="apply-filter"
                            onClick={this.handleApplyAllColumnFilters}
                            color="primary"
                        >
                            <CheckIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Clear Column Filters" placement="top">
                        <IconButton
                            data-cy="clear-filter"
                            onClick={this.handleColumnFilterClose}
                            color="secondary"
                            disabled={isLoadingData}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        );
    }
}

export default withState(['showColumnFilters'])(
    withProps(usedProps)(withStyles(styles)(ResultsTableColumnFilters))
);
