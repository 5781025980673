import { makeStyles } from '@material-ui/styles';
import { Chip, Paper, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AddBoxIcon from '@material-ui/icons/Add';
import { useTopRatedProgramsState } from './TopRatedProgramsProvider';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: theme.spacing(2),
    },
    root: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1),
    },
    filterChip: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

export default function DataFilter() {
    const classes = useStyles();
    const { drawerFilters, setTopRatedProgramsState } =
        useTopRatedProgramsState();
    const handleAddFilter = () =>
        setTopRatedProgramsState({ dataFilterOpen: true });

    const handleDeleteFilter = (key) => () => {
        setTopRatedProgramsState((prev) => {
            const { [key]: _, ...newFilters } = prev.drawerFilters;
            return {
                ...prev,
                drawerFilters: newFilters,
            };
        });
    };

    const hasFilters = Object.keys(drawerFilters).length > 0;

    return (
        <Paper className={classes.paper}>
            <div className={classes.root}>
                <Typography variant="overline">Data Filters</Typography>
                <br />
                {Object.keys(drawerFilters)
                    .filter((key) => ['directory', 'country'].includes(key))
                    .map((key) => {
                        if (!drawerFilters[key]) return null;
                        const { label } = drawerFilters[key] || {};
                        return (
                            <Chip
                                label={label}
                                color="primary"
                                onDelete={handleDeleteFilter(key)}
                                onClick={handleAddFilter}
                                className={classes.filterChip}
                            />
                        );
                    })}

                <Chip
                    icon={
                        hasFilters ? (
                            <EditIcon fontSize="small" />
                        ) : (
                            <AddBoxIcon fontSize="small" />
                        )
                    }
                    data-cy="top-rated-programs-add-data-filter"
                    label={hasFilters ? 'Edit Filters' : 'Add Filters'}
                    onClick={handleAddFilter}
                    color="primary"
                    variant="outlined"
                    className={classes.filterChip}
                />
            </div>
        </Paper>
    );
}
