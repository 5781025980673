import React, { Component, useContext } from 'react';

import { GlobalContext } from '../../context';
import { delete_cookie, getUserCookie } from '../../helpers/cookieUtility';
import { sendRequest } from '../../helpers/apiRequestUtility';
import { isEmpty } from 'node-forge/lib/util';
import { GA_API } from '../../config/constants';
import { trimSlash } from '../../helpers/dataUtility';
import { AbilityContext } from '../../components/AccessLevel/abilityContext';

class GlobalProvider extends Component {
    static contextType = AbilityContext;
    state = {
        /**
         * {Array} Array of Clients object
         *
         * Object of Client:
         * {
         *   id:                        {Integer}, Index of the client
         *   name:                      {String}
         * }
         */
        providers: [],

        /**
         * {Array} Array of active client
         *
         * Oblject:
         * {
         *     id:      {Integer}
         *     name:    {String}
         * }
         */
        activeProviders: [],

        /**
         * {Provider} Selected Provider object
         *
         */
        selectedProvider: null,

        /**
         * Object whose keys are Search Criteria's new_criteria
         * Stores searchable options fetchead from Proxy to avoid redundant network requests
         *
         * Object of scBuffer
         * {
         *   degree_program: {Object of type}
         *   term: {Object of timing}
         *   ...
         * }
         */
        scBuffer: {},

        /**
         * {Array} Array of type objects
         *
         * Object of type
         * {
         *   alias:           {String},
         *   id:              {Integer},
         *   name:            {String}
         *   search_criteria: {Array of search_criteria objects},
         * }
         *
         * Object of search_criteria
         * {
         *   criteria_id:    {Integer},
         *   criteria_value: {Integer},
         * }
         */
        types: [],

        /**
         * {Array} Array of timing objects
         *
         * Object of timing
         * {
         *   alias:           {String},
         *   id:              {Integer},
         *   name:            {String}
         *   search_criteria: {Array of search_criteria objects},
         * }
         *
         * Object of search_criteria
         * {
         *   criteria_id:    {Integer},
         *   criteria_value: {Integer},
         * }
         */
        timings: [],

        /**
         * {Array} Array of online objects
         *
         * Object of online
         * {
         *   alias:           {String},
         *   id:              {Integer},
         *   name:            {String}
         *   search_criteria: {Search_criteria object},
         * }
         *
         * Object of search_criteria
         * {
         *   criteria_id:    {Integer},
         *   criteria_value: {Integer},
         * }
         */
        onlines: [
            {
                id: 1,
                name: 'Online',
                alias: 'online',
                search_criteria: {
                    criteria_id: 32768,
                    criteria_value: 1,
                },
            },
        ],

        /**
         * User object
         *
         * Object of User:
         * {
         *   id:            {Integer},    Index of the User
         *   email:         {String},
         *   access_level:  {Integer},
         *   first_name:    {String},
         *   last_name:     {String},
         *   job_title:     {String},
         *   status:        {null|Date},
         *   date_created:  {null|Date},
         *   date_updated:  {null|Date},
         *   date_deleted:  {null|Date},
         *   provider_id:   {Integer}
         *   phone:         {String},
         *   is_primary:    {Integer/Boolean},
         *   is_billing:    {Integer/Boolean},
         *   is_phone_log:  {String},
         *   photo:         {String},
         *   team_id:       {null|Integer},
         *   nick_name:     {String},
         *   hire_date:     {null|Date},
         *   is_public:     {Integer/Boolean},
         *   department_id: {null|Integer}
         *   employee_id:   {null|Integer}
         * }
         */
        user: {
            id: null,
            email: '',
            access_level: 0,
            first_name: '',
            last_name: '',
            job_title: '',
            status: 0,
            date_created: null,
            date_updated: null,
            date_deleted: null,
            provider_id: null,
            phone: '',
            is_primary: 0,
            is_billing: 0,
            is_phone_log: 0,
            photo: '',
            team_id: null,
            nick_name: '',
            hire_date: null,
            is_public: null,
            department_id: null,
            employee_id: null,
        },

        /**
         * {Array} Array of ProductItems object
         *
         * Object of ProductItem:
         * {
         *   id:                    {Integer},    Index of the Invoice
         *   item_name:             {String},
         *   description:           {String},
         *   cost:                  {Float},
         *   directory_id:          {Integer},
         *   search_criteria_value: {Integer},
         *   ad_type_id:            {Integer}
         * }
         */
        productItems: [],

        countries: [],

        countriesWorldRegion: [],

        worldRegions: [],

        locations: [],

        accountManagers: [],

        directories: [],

        teams: [],

        /**
         * {Array} Array of Ads object
         *
         * Object of Ads:
         * {
         *   id:                        {Integer}, Index of the ad
         *   name:                      {String}
         * }
         */
        ads: [],

        /**
         * {Array} Array of Author object
         *
         * Object of Ads:
         * {
         *   id:                        {Integer}, Index of the author
         *   name:                      {String}
         * }
         */
        authors: [],

        /**
         * {Array} Array of age ranges object
         *
         * Object of Ads:
         * {
         *   id:                        {Integer}, Index of the age range
         *   name:                      {String}
         * }
         */
        ageRanges: [],

        /**
         * {Array} Array of education status object
         *
         * Object of Ads:
         * {
         *   id:                        {Integer}, Index of the education status
         *   name:                      {String}
         * }
         */
        educationStatus: [],

        /**
         * {Array} Array of review categories object
         *
         * Object of Review Categories:
         * {
         *   id:                        {Integer}, Index of the category
         *   name:                      {String}
         *   directory_id               {Integer}
         * }
         */
        reviewCategories: [],

        isLoading: false,

        fetchingProviders: false,

        fetchingCountries: false,

        fetchingCountriesWorldRegion: false,

        fetchingWorldRegions: false,

        fetchingTeams: false,

        fetchingLocations: false,

        fetchingAccountManagers: false,

        fetchingProductItems: false,

        fetchingDirectories: false,

        fetchingAds: false,

        fetchingAgeRanges: false,

        fetchingEducationStatus: false,

        fetchingReviewCategories: false,

        articleDrawerOpen: false,

        articleExportDialogOpen: false,

        collectionExportDialogOpen: false,

        showLoginDialog: false,

        publishBadgesDialogOpen: false,

        loginDialogCallback: () => {},
    };

    componentDidMount() {
        let user_info = getUserCookie();

        if (!!user_info) this.setState({ user: user_info });
    }

    /**
     * Updates specified state
     *
     * @param {Object} newState contains the name of the state and its new value
     */
    handleStateChange = (newState, callback) =>
        this.setState(newState, callback);
    /**
     * Fetch searchable options from Proxy and set Context's timing or type values
     * Store the searchable option in Context's scBuffer to avoid redundant network request
     *
     * @param {String} key               State in Context whose value will be set based on the criteria
     * @param {String|Object} criteria   Contains the searchable option criteria that will be fetched from Proxy
     *
     * Object of criteria
     * {
     *   new_criteria: {String},
     *   bin_value: {Integer}
     * }
     */
    fetchSearchableOptionsByCriteria = async (key, criteria) => {
        let object = { [key]: [] };
        const { scBuffer } = this.state;

        if (criteria) {
            if (criteria.new_criteria in scBuffer) {
                object[key] = scBuffer[criteria.new_criteria];
                this.handleStateChange(object);
            } else {
                this.handleStateChange({ isLoading: true });
                await this.authenticatedGetRequest(
                    `/searchable-options?criteria=${criteria.new_criteria}`,
                    (json) => {
                        object[key] = json.searchable_options.map((option) => {
                            option['search_criteria'] = {
                                criteria_id: criteria.bin_value,
                                criteria_value: option.id,
                            };
                            return option;
                        });

                        let scBufferNew = scBuffer;
                        scBufferNew[criteria.new_criteria] = object[key];
                        object['scBuffer'] = scBufferNew;
                        object.isLoading = false;
                        this.handleStateChange(object);
                    },
                    () => {},
                    () => this.fetchSearchableOptionsByCriteria(key, criteria)
                );
            }
        } else {
            this.handleStateChange(object);
        }
    };

    /**
     * Fetch all providers for global use
     * @param {Boolean} force   A boolean option to force fetch and replace providers previously fetched
     */
    fetchProviders = async (force = false) => {
        const { providers } = this.state;

        this.setState({ fetchingProviders: isEmpty(providers) || force });

        if (isEmpty(providers) || force)
            await sendRequest(
                '/providers?fields=name,id&sort=+name',
                ({ providers }) => {
                    this.setState({
                        providers: providers || [],
                        fetchingProviders: false,
                    });
                }
            );

        return this.state.providers;
    };

    /**
     * Fetch all active providers for global use
     * @param {Boolean} force   A boolean option to force fetch and replace active providers previously fetched
     */
    fetchActiveProviders = async (force = false) => {
        const { activeProviders } = this.state;

        this.setState({
            fetchingActiveProviders: isEmpty(activeProviders) || force,
        });

        if (isEmpty(activeProviders) || force)
            await sendRequest(
                '/providers?fields=name,id&sort=+name&is_active=1',
                ({ providers }) => {
                    this.setState({
                        activeProviders: providers || [],
                        fetchingActiveProviders: false,
                    });
                }
            );

        return this.state.activeProviders;
    };

    /**
     * Fetch all countries for global use
     * @param {Boolean} force   A boolean option to force fetch and replace countries previously fetched
     */
    fetchCountries = async (force = false) => {
        const { countries } = this.state;

        this.setState({ fetchingCountries: isEmpty(countries) || force });

        if (isEmpty(countries) || force)
            await sendRequest('/locations/countries', ({ countries }) =>
                this.setState({
                    countries: countries || [],
                    fetchingCountries: false,
                })
            );

        return this.state.countries;
    };

    /**
     * Fetch all countries with world region for global use
     * @param {Boolean} force   A boolean option to force fetch and replace countries previously fetched
     */
    fetchCountriesWorldRegion = async (force = false) => {
        const { countriesWorldRegion } = this.state;

        this.setState({
            fetchingCountriesWorldRegion:
                isEmpty(countriesWorldRegion) || force,
        });

        if (isEmpty(countriesWorldRegion) || force)
            await sendRequest(
                '/locations/countries-world-region',
                ({ countries }) => {
                    const filteredCountries = countries
                        .filter(
                            (p) =>
                                p.name !== 'The Caribbean' &&
                                p.name !== 'South '
                        )
                        .map((country) => {
                            if (country.name === 'Multiple Countries')
                                return {
                                    id: country.id,
                                    name: country.name,
                                    world_region_id: 0,
                                };
                            else return country;
                        });
                    this.setState({
                        countriesWorldRegion: filteredCountries || [],
                        fetchingCountriesWorldRegion: false,
                    });
                }
            );

        return this.state.countriesWorldRegion;
    };

    /**
     * Fetch all countries with world region for global use
     * @param {Boolean} force   A boolean option to force fetch and replace countries previously fetched
     */
    fetchWorldRegions = async (force = false) => {
        const { worldRegions } = this.state;

        this.setState({ fetchingWorldRegions: isEmpty(worldRegions) || force });

        if (isEmpty(worldRegions) || force)
            await sendRequest(
                '/locations/world-regions',
                ({ world_regions }) => {
                    this.setState({
                        worldRegions:
                            world_regions.filter(
                                (p) => p.name !== 'Araucania'
                            ) || [],
                        fetchingWorldRegions: false,
                    });
                }
            );

        return this.state.worldRegions;
    };

    /**
     * Fetch all teams for global use
     * @param {Boolean} force   A boolean option to force fetch and replace teams previously fetched
     */
    fetchTeams = async (force = false) => {
        const { teams } = this.state;

        this.setState({ fetchingTeams: isEmpty(teams) || force });

        if (isEmpty(teams) || force)
            await sendRequest(
                '/teams?limit=-1&sort=+name&fields=id,name',
                ({ teams }) =>
                    this.setState({ teams: teams || [], fetchingTeams: false })
            );

        return this.state.teams;
    };

    /**
     * Fetch all account managers for global use
     * @param {Boolean} force   A boolean option to force fetch and replace account managers previously fetched
     */
    fetchAccountManagers = async (force = false) => {
        const { accountManagers } = this.state;

        this.setState({
            fetchingAccountManagers: isEmpty(accountManagers) || force,
        });

        if (isEmpty(accountManagers) || force)
            await sendRequest(
                '/employees/account-managers',
                ({ account_managers }) => {
                    this.setState({
                        accountManagers: account_managers || [],
                        fetchingAccountManagers: false,
                    });
                }
            );

        return;
    };

    /**
     * Fetch all locations for global use
     * @param {Boolean} force   A boolean option to force fetch and replace locations previously fetched
     */
    fetchLocations = async (force = false, type = 'country,city') => {
        const { locations } = this.state;

        this.setState({ fetchingLocations: isEmpty(locations) || force });

        if (isEmpty(locations) || force)
            await sendRequest(
                `/locations${type ? `?location_type=${type}` : ''}`,
                ({ locations }) => {
                    this.setState({
                        locations: locations || [],
                        fetchingLocations: false,
                    });
                }
            );

        return this.state.locations;
    };

    /**
     * Fetch all directories for global use
     * @param {Boolean} force   A boolean option to force fetch and replace directories previously fetched
     */
    fetchDirectories = async (force = false) => {
        const { directories } = this.state;

        this.setState({ fetchingDirectories: isEmpty(directories) || force });

        if (isEmpty(directories) || force)
            await sendRequest('/directories', ({ directories }) => {
                this.setState({
                    directories: directories || [],
                    fetchingDirectories: false,
                });
            });

        return this.state.directories;
    };

    /**
     * Fetch all product items for global use
     * @param {Boolean} force   A boolean option to force fetch and replace product items previously fetched
     */
    fetchProductItems = async (force = false) => {
        const { productItems } = this.state;

        this.setState({ fetchingProductItems: isEmpty(productItems) || force });

        if (isEmpty(productItems) || force)
            await sendRequest(
                '/invoices/product-items?limit=-1&is_active=1',
                ({ product_items }) => {
                    this.setState({
                        productItems: product_items || [],
                        fetchingProductItems: false,
                    });
                }
            );

        return this.state.productItems;
    };

    /**
     * Fetch all ads for global use
     * @param {Boolean} force   A boolean option to force fetch and replace ads previously fetched
     */
    fetchAds = async (force = false) => {
        const { ads } = this.state;

        this.setState({ fetchingAds: isEmpty(ads) || force });

        if (isEmpty(ads) || force)
            await sendRequest('/ads?limit=-1', ({ ads }) => {
                let mappedAds = ads.map(({ id, ad_name }) => {
                    return { id, name: ad_name };
                });
                this.setState({
                    ads: mappedAds || [],
                    fetchingAds: false,
                });
            });

        return this.state.ads;
    };

    /**
     * Fetch all authors for global use
     * @param {Boolean} force   A boolean option to force fetch and replace authors previously fetched
     */
    fetchAuthors = async (force = false) => {
        let authors = this.state.authors || [];

        this.setState({ fetchingAuthors: isEmpty(authors) || force });

        if (isEmpty(authors) || force) {
            authors = await sendRequest(
                '/authors?limit=-1',
                ({ authors }) => authors || [],
                () => []
            );
            authors.sort((a, b) => {
                let fa = a.name.toString().replace(/\s+/g, ''),
                    fb = b.name.toString().replace(/\s+/g, '');
                if (fa > fb) return 1;
                if (fa < fb) return -1;
                return 0;
            });
            this.setState({
                authors: authors || [],
                fetchingAuthors: false,
            });
        }

        return authors;
    };

    /**
     * Fetch all age ranges
     * @param {Boolean} force   A boolean option to force fetch and replace age ranges previously fetched
     */
    fetchAgeRanges = async (force = false) => {
        let ageRanges = this.state.ageRanges || [];

        this.setState({ fetchingAgeRanges: isEmpty(ageRanges) || force });

        if (isEmpty(ageRanges) || force) {
            ageRanges = await sendRequest(
                '/programs/age-ranges?limit=-1',
                ({ age_ranges }) => age_ranges || [],
                () => []
            );
            this.setState({
                ageRanges: ageRanges || [],
                fetchingAgeRanges: false,
            });
        }

        return ageRanges;
    };

    /**
     * Fetch all education status
     * @param {Boolean} force   A boolean option to force fetch and replace education status previously fetched
     */
    fetchEducationStatus = async (force = false) => {
        let educationStatus = this.state.educationStatus || [];

        this.setState({
            fetchingEducationStatus: isEmpty(educationStatus) || force,
        });

        if (isEmpty(educationStatus) || force) {
            educationStatus = await sendRequest(
                '/education-status?limit=-1',
                ({ education_status }) => education_status || [],
                () => []
            );
            this.setState({
                educationStatus: educationStatus || [],
                fetchingEducationStatus: false,
            });
        }

        return educationStatus;
    };

    /**
     * Fetch all review categories
     * @param {Boolean} force   A boolean option to force fetch and replace review categories previously fetched
     */
    fetchReviewCategories = async (force = false) => {
        let reviewCategories = this.state.reviewCategories || [];

        this.setState({
            fetchingReviewCategories: isEmpty(reviewCategories) || force,
        });

        if (isEmpty(reviewCategories) || force) {
            reviewCategories = await sendRequest(
                '/reviews/categories?fields=id,name,directory_id&limit=-1',
                ({ categories }) => categories || [],
                () => []
            );
            this.setState({
                reviewCategories: reviewCategories || [],
                fetchingReviewCategories: false,
            });
        }

        return reviewCategories;
    };

    authenticatedPostRequest = (
        relative_url,
        params,
        callback,
        error,
        initiator = () => {}
    ) => {
        let status = 200;
        const response = fetch(
            `${trimSlash(GA_API)}/${trimSlash(relative_url)}`,
            {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                },
                credentials: 'include',
                body: params,
            }
        )
            .then((response) => {
                status = response.status;
                if (response.status === 401) {
                    delete_cookie('user_info', '/');
                    this.setState({
                        showLoginDialog: true,
                        loginDialogCallback: initiator,
                    });
                    return { code: 401, error: 'Unauthorized.' };
                }
                return response.json();
            })
            .then((json) => {
                if (status === 422) return !!error ? error(json) : json;
                if (!!json.error) return !!error ? error(json) : json;
                else return !!callback ? callback(json) : json;
            })
            .catch((error_response) =>
                !!error
                    ? error({ code: status, error: error_response })
                    : error_response
            );

        return response;
    };

    authenticatedGetRequest = (
        relative_url,
        callback = () => {},
        error = () => {},
        initiator = () => {}
    ) => {
        let status = 200;
        const response = fetch(
            `${trimSlash(GA_API)}/${trimSlash(relative_url)}`,
            {
                headers: {
                    Accept: 'application/json',
                },
                credentials: 'include',
            }
        )
            .then((response) => {
                status = response.status;
                if (response.status === 401) {
                    delete_cookie('user_info', '/');
                    this.setState({
                        showLoginDialog: true,
                        loginDialogCallback: initiator,
                    });
                    return { code: 401, error: 'Unauthorized.' };
                }
                return response.json();
            })
            .then((json) => {
                if (!!json.error) return error(json);
                else return callback(json);
            })
            .catch((error_response) =>
                error({ code: status, error: error_response })
            );

        return response;
    };

    render() {
        return (
            <GlobalContext.Provider
                value={{
                    state: this.state,
                    ability: this.context,
                    setGlobalState: this.handleStateChange,
                    handleStateChange: this.handleStateChange,
                    fetchSearchableOptionsByCriteria:
                        this.fetchSearchableOptionsByCriteria,
                    fetchProviders: this.fetchProviders,
                    fetchActiveProviders: this.fetchActiveProviders,
                    fetchCountries: this.fetchCountries,
                    fetchCountriesWorldRegion: this.fetchCountriesWorldRegion,
                    fetchWorldRegions: this.fetchWorldRegions,
                    fetchTeams: this.fetchTeams,
                    fetchAccountManagers: this.fetchAccountManagers,
                    fetchLocations: this.fetchLocations,
                    fetchDirectories: this.fetchDirectories,
                    fetchProductItems: this.fetchProductItems,
                    fetchAds: this.fetchAds,
                    fetchAuthors: this.fetchAuthors,
                    fetchAgeRanges: this.fetchAgeRanges,
                    fetchEducationStatus: this.fetchEducationStatus,
                    fetchReviewCategories: this.fetchReviewCategories,
                    authenticatedPostRequest: this.authenticatedPostRequest,
                    authenticatedGetRequest: this.authenticatedGetRequest,
                    fetchSearchCriteriaOptions: this.fetchSearchCriteriaOptions,
                }}
            >
                {this.props.children}
            </GlobalContext.Provider>
        );
    }
}

export const withGlobalContext = (Component) => (props) => {
    return (
        <GlobalContext.Consumer>
            {(context) => <Component globalContext={context} {...props} />}
        </GlobalContext.Consumer>
    );
};

export const useGlobalState = () => {
    const { state, handleStateChange } = useContext(GlobalContext);
    return { ...state, handleStateChange, setGlobalState: handleStateChange };
};

export const useGlobalMethods = () => {
    const { state, ...methods } = useContext(GlobalContext);
    return methods;
};

export const useAuthenticatedRequest = () => {
    const {
        authenticatedPostRequest: postRequest,
        authenticatedGetRequest: sendRequest,
    } = useContext(GlobalContext);
    return { postRequest, sendRequest };
};

export default GlobalProvider;
