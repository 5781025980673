export const BOOKMARKABLE_FIELDS = {
    accounts_view: [
        'is_paying',
        'is_active',
        'team_id',
        'description_status',
        'verification_status_id',
        'requirement_upload_status',
        'preferred_oai',
    ],
    invoices_view: [
        'provider_id',
        'is_active',
        'team_id',
        'invoice_date',
        'due_date',
        'payment_status',
    ],
    ads_view: [
        'status',
        'provider_id',
        'team_id',
        'is_active',
        'page',
        'rowsPerPage',
        'page',
        'item_id',
    ],

    programs_view: [
        'account_status',
        'team_id',
        'listing_type',
        'provider_id',
        'listing_status',
        'program_id',
        'online',
        'top_pick',
        'is_generic',
    ],
    collections_view: ['provider_id', 'team_id', 'date_created'],
    reviews_view: [
        'provider_id',
        'status',
        'from_goeval',
        'is_approved',
        'content_status',
    ],
    articles_view: ['article_id'],
    guides_view: [
        'author_id',
        'status',
        'date_published',
        'date_modified',
        'page_url',
        'bin_value',
        'directory_id',
        'country_id',
        'city_id',
        'main_region_id',
        'world_region_id',
        'provider_id',
        'adventure_type_id',
        'degree_id',
        'duration_id',
        'gap_year_timing_id',
        'gap_year_type_id',
        'highschool_type_id',
        'intern_duration_id',
        'intern_type_id',
        'language_id',
        'study_type_id',
        'teach_duration_id',
        'term_id',
        'volunteer_type_id',
        'online',
    ],
    provider_url_verifiers_view: ['status', 'team_id'],
    program_url_verifiers_view: ['status', 'team_id'],
    ad_url_verifiers_view: ['status', 'team_id'],
    scholarship_view: ['deadline_date', 'sort_deadline'],
    deals_view: ['deal_status'],
    program_ranking: [
        'provider_id',
        'directory_id',
        'country_id',
    ],
};

export const isBookmarkable = (view, field) => {
    return BOOKMARKABLE_FIELDS[view].includes(field);
};
