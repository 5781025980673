import React, { useContext, useEffect } from 'react';
import { ProgramRankingContext } from '../../../context';
import { useObjectState } from '../../../utilities/customHooks';
import {
    arrayDeepCopy,
    constructHttpParams
} from '../../../helpers/dataUtility';
import { sendRequest } from '../../../helpers/apiRequestUtility';
import getCriteriaByDirectory from '../../../utilities/searchCriteria';
import queryString from 'query-string';
import { isBookmarkable } from '../../../utilities/boomarkability';
import { hasValue } from '../../../helpers/dataUtility';
import { DIRECTORIES } from '../../../utilities/directory';

const { Provider } = ProgramRankingContext;

const ProgramRankingProvider = (props) => {
    const [state, setState] = useObjectState({
        data: [],
        filters: {},
        reorder: false,
        fetching: false,
        filterDrawerOpen: true,
        sortDirection: 'desc',
        rankLoading: false,
        locations: [],
        types: [],
        timings: [],
        dialog: {
            showDialog: false,
            title: '',
            content: '',
            onOk: () => {},
            onCancel: () => {},
            stringOverride: {}
        },
        provider: {},
        directory: {},
        country: {},
    });

    const setFilters = (filters) =>
        setState((prev) => ({
            ...prev,
            filters: { ...prev.filters, ...filters }
        }));

    const removeFilters = (keys = []) =>
        setState((prev) => {
            let newFilters = { ...prev.filters };
            //eslint-disable-next-line array-callback-return
            keys.map((key) => {
                const { [key]: filter, ...others } = newFilters;
                newFilters = others;
            });
            return {
                ...prev,
                filters: newFilters
            };
        });

    const fetchProviderPrograms = () => {
        const { filters = {} } = state;
        const locationCriteria = (filters.location || '').split(',').reduce((current, search_criteria) => {
            const [id, val] = search_criteria.split('-');
            return {...current, [id]: val};
        }, {});
        const { [filters.directory_id]: search_criteria } =
            getCriteriaByDirectory({
                key: 'id',
                val: [filters.directory_id]
            });

        const params = constructHttpParams({
            provider_id: filters.provider_id,
            directory_id: filters.directory_id,
            ...locationCriteria,
            [(search_criteria.type || {}).id || '']: filters.type,
            [(search_criteria.timing || {}).id || '']: filters.timing,
            online: filters.online ? '1,2' : '0,1',
            is_active: 1,
            account_status: 1,
            limit: -1,
            include_approved_reviews_only: 1,
            sort: '-ranking,-reviews_count,-date_edited',
            fields: 'id,name,provider_id,client_alias,url_alias,ranking,reviews_count,date_edited'
        });

        setState({ fetching: true });
        sendRequest(`/programs?${params}`, ({ programs = [] } = {}) => {
            programs.map((program) => {
                program.reorder =
                    !!program.ranking && programs.length - program.ranking + 1;
                return program;
            });
            sortData(programs);
        }).finally(() => {
            setState({ fetching: false });
        });
    };

    const sortData = (rawData = state.data) => {
        const { sortDirection } = state;
        setState({
            data: [...rawData].sort((a, b) => {
                if (!a.ranking && !!b.ranking) return 0;
                if (sortDirection === 'desc') return b.ranking - a.ranking;
                if (sortDirection === 'asc') return a.ranking - b.ranking;
                return 0;
            })
        });
    };

    const onDrop = (dropIndex) => {
        let { temp, dragged_index } = state;
        const oldFeatures = arrayDeepCopy(temp);
        let draggedIndex = dragged_index;
        let draggedAd = oldFeatures[draggedIndex];

        if (draggedIndex < dropIndex) {
            for (let index = draggedIndex; index < dropIndex; index++) {
                temp[index] = oldFeatures[index + 1];
            }
            temp[dropIndex] = draggedAd;
        } else if (draggedIndex > dropIndex) {
            for (let index = draggedIndex; index > dropIndex; index--) {
                temp[index] = oldFeatures[index - 1];
            }
            temp[dropIndex] = draggedAd;
        }
        setState({ temp });
    };

    const handleErrorDialog = () => {
        const dialog = {
            actions: 'Ok',
            title: 'Oh no! Ranking of programs was unsuccessful, please try again.',
            showDialog: true,
            onOk: handleCloseDialog,
            stringOverride: { primaryAction: 'OK' }
        };
        setState({ dialog: dialog });
    };

    const handleCloseDialog = () => {
        const dialog = {
            showDialog: false
        };
        setState({ dialog: dialog });
    };

    const getSearchCriteria = () => {
        const { filters } = state;
        const locationCriteria = (filters.location || '').split(',').reduce((current, search_criteria) => {
            const [id, val] = search_criteria.split('-');
            return {...current, [id]: val};
        }, {});
        const { [filters.directory_id]: search_criteria } =
            getCriteriaByDirectory({
                key: 'id',
                val: [filters.directory_id]
            });
        const searchCriteria = {
            directory_id: filters.directory_id,
            ...locationCriteria,
            [(search_criteria.type || {}).id || '']: filters.type,
            [(search_criteria.timing || {}).id || '']: filters.timing,
            online: filters.online ? '1,2' : null
        };
        const filtered = {};

        Object.keys(searchCriteria)
            .filter((key) => !!key && !!searchCriteria[key])
            //eslint-disable-next-line array-callback-return
            .map((key) => {
                filtered[key] = searchCriteria[key];
            });

        return filtered;
    };

    const filterValidBookMarkableFields = (array) => {
        let bookmarkable_fields = {};

        Object.keys(array).forEach((param) => {
            if (
                isBookmarkable('program_ranking', param) &&
                hasValue(array[param])
            )
                bookmarkable_fields[param] = array[param].toString();
        });

        return bookmarkable_fields;
    };

    const fetchProvider = async (provider_id) => {
        await sendRequest(
            '/providers?id='+provider_id+'&fields=name,id&sort=+name',
            ({ providers }) => {
                setState({
                    provider: providers[0]
                })
            }
        );
    };

    const fetchDirectory = async (directory_id) => {
        setState({
            directory: DIRECTORIES.filter(
                (dir) => dir.id === parseInt(directory_id)
            )[0]
        })
    };

    const fetchCountry = async (country_id) => {
        await sendRequest(
            `/locations/countries?id=${country_id}`,
            ({countries}) => {
                setState({
                    country: countries[0]
                })
            }
        );
    };

    useEffect(() => {
        let search_params = queryString.parse(window.location.search);
        let filters = filterValidBookMarkableFields(search_params);
        if (!!filters.provider_id){
            fetchProvider(filters.provider_id)
        }
        if (!!filters.directory_id){
            fetchDirectory(filters.directory_id)
        }
        if (!!filters.country_id){
            fetchCountry(filters.country_id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (state.filters.provider_id && state.filters.provider_id)
            fetchProviderPrograms();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        state.filters.provider_id,
        state.filters.directory_id,
        state.filters.location,
        state.filters.type,
        state.filters.timing,
        state.filters.online
    ]);

    useEffect(() => {
        sortData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.sortDirection]);

    useEffect(() => {
        if (state.reorder) {
            setState({ sortDirection: 'desc', temp: [...state.data] });
            removeFilters(['program_id']);
        } else setState({ temp: [] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.reorder, state.data]);

    return (
        <Provider
            value={{
                state,
                setProgramRankingState: setState,
                setFilters,
                removeFilters,
                fetchProviderPrograms,
                sortData,
                onDrop,
                handleErrorDialog,
                handleCloseDialog,
                getSearchCriteria
            }}
        >
            {props.children}
        </Provider>
    );
};

export const useProgramRankingState = () => {
    const { state, setProgramRankingState } = useContext(ProgramRankingContext);
    return { ...state, setProgramRankingState };
};

export const useProgramRankingMethods = () => {
    const { state, ...methods } = useContext(ProgramRankingContext);
    return methods;
};

export default ProgramRankingProvider;
