import React, { useEffect } from 'react';
import ResultsTable from '../../../components/ResultsTable/ResultsTable';
import {
    useObjectState,
    useSnackbarMessage,
} from '../../../utilities/customHooks';
import {
    useTopRatedProgramsState,
    useTopRatedProgramsMethods,
} from './TopRatedProgramsProvider';
import CustomGlobalFilterDrawer from '../../../components/CustomGlobalFilter/CustomGlobalFilterDrawer';
import getDirectoryBy, { DIRECTORIES } from '../../../utilities/directory';
import { useGlobalState } from '../../../views/Client/GlobalProvider';
import DialogBox from '../../../components/DialogBox/DialogBox';
import LinearProgress from '@material-ui/core/LinearProgress';
import { postRequest, sendRequest } from '../../../helpers/apiRequestUtility';
import { GA_URL } from '../../../config/constants';
import { Typography, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { red } from '@material-ui/core/colors';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { dataURItoBlob } from '../../../helpers/dataUtility';
import { trimSlash } from '../../../helpers/dataUtility';
import { intersection, keys } from 'lodash';
import { cloudinaryImage } from '../../../helpers/cloudinary';

const useStyles = makeStyles((theme) => ({
    unpublished: {
        color: '#fff',
        fontSize: 10,
        width: 90,
        height: 20,
        verticalAlign: 'middle',
        display: 'inline-flex',
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        background: red[500],
    },
    unqualified: {
        color: '#fff',
        fontSize: 10,
        width: 90,
        height: 20,
        verticalAlign: 'middle',
        display: 'inline-flex',
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        background: red[500],
    },
    row: {
        '& td:nth-child(1)': {
            width: 60,
            minWidth: 60,
        },
        '& td:nth-child(2)': {
            width: 150,
            minWidth: 150,
        },
        '& td:nth-child(3)': {
            width: 80,
            minWidth: 80,
        },
        '& td:nth-child(4)': {
            width: 250,
            minWidth: 200,
        },
        '& td:nth-child(5)': {
            width: 120,
            minWidth: 120,
        },
        '& td:nth-child(6)': {
            width: 120,
            minWidth: 120,
        },
        '& td:nth-child(7)': {
            width: 60,
            minWidth: 60,
        },
        '& td:nth-child(8)': {
            width: 60,
            minWidth: 60,
        },
        '& td:nth-child(9)': {
            width: 60,
            minWidth: 60,
        },
        '& td:last-child': {
            width: 40,
            minWidth: 40,
        },
    },
    '.Mui-disabled svg': {
        fill: 'red',
    },
}));

export default function ResultsMain() {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { publishBadgesDialogOpen, setGlobalState } = useGlobalState();
    const today = new Date();
    const monthXdir = [0, 5, 2, 11, 8, 6, 7, 4, 9, 19, 3, 12];

    const currentDate = moment().format('YYYY-MM-DD');
    const endYear = moment(currentDate).year();
    const startYear = endYear - 20;
    let years = Array.from(
        { length: (endYear - startYear) / 1 + 1 },
        (_, i) => endYear - i * 1
    );
    years = years.map((key) => ({ id: key, name: key }));

    const { showError } = useSnackbarMessage(enqueueSnackbar);

    const {
        data,
        directories,
        count,
        countries,
        clients,
        dialog,
        fetching,
        filters,
        globalFilters,
        globalFilterOpen,
        programs,
        disablePublish,
        disableQualify,
        rowsPerPage,
        page,
        selected,
        saving,
        linearProgressTitle,
    } = useTopRatedProgramsState();

    const {
        setTopRatedProgramsState,
        fetchTopRatedPrograms,
        handleExportTopRatedPrograms,
    } = useTopRatedProgramsMethods();

    const [state, setState] = useObjectState({
        columnFilters: {},
        programData: {},
        thisRow: 0,
        url_alias: '',
        toPublish: [],
        toPublishCount: 0,
        year: 0,
        month: 0,
        thisMonthDirectory: 0,
        yearColumnFilter: {},
        clientNameColumnFilter: {},
        listingIDColumnFilter: {},
        programColumnFilter: {},
        directoryColumnFilter: {},
        countryColumnFilter: {},
        directoryName: '',
    });

    const rowMenus = [
        {
            name: 'manage',
            text: 'Manage Program Ranking',
            target: '_blank',
            handler: (row) => {
                window.location.href = `/ranking-system/program-ranking?provider_id=${
                    row.providerID
                }&directory_id=${row.directoryID}&country_id=${
                    !!row.countryDetails ? row.countryDetails.id : ''
                }`;
            },
        },
        {
            name: 'view-sr',
            text: 'View SR Page',
            target: '_blank',
        },
        {
            name: 'view',
            text: 'View Listing Page',
            target: '_blank',
        },
        {
            name: 'unqualify',
            text: 'Unqualify',
            handler: (row) => {
                handleOpenDialog(
                    'Unqualify Top Rated Program',
                    'Are you sure you want to unqualify this program?',
                    () => {
                        handleUnqualify(false, row);
                    }
                );
            },
        },
        {
            name: 'qualify',
            text: 'Qualify',
            handler: (row) => {
                handleOpenDialog(
                    'Qualify Top Rated Program',
                    'Are you sure you want to qualify this program?',
                    () => {
                        handleQualify(false, row);
                    }
                );
            },
        },
    ];

    const batchActions = [
        {
            icon: 'export',
            handler: () =>
                handleBatchDialogConfirmation(
                    'Export Top Rated Programs',
                    '',
                    handleExportTopRatedPrograms,
                    'Download All Columns & Top Rated Program Fields as CSV'
                ),
            enableInSelectAll: true,
        },
        {
            icon: 'unqualify',
            handler: () => {
                handleDialogConfirmation(
                    'Unqualify',
                    `unqualify ${selected.length} top rated program${
                        selected.length === 1 ? '' : 's'
                    }`,
                    () => handleUnqualify(true)
                );
            },
            disabled: disablePublish ? disableQualify : !disableQualify,
        },
        {
            icon: 'qualify',
            handler: () => {
                handleDialogConfirmation(
                    'Qualify',
                    `qualify ${selected.length} top rated program${
                        selected.length === 1 ? '' : 's'
                    }`,
                    () => handleQualify(true)
                );
            },
            disabled: disableQualify,
        },
        {
            icon: 'publish',
            handler: () => {
                handleDialogConfirmation(
                    'Publish',
                    `publish ${selected.length} top rated program${
                        selected.length === 1 ? '' : 's'
                    }`,
                    () => handlePublish()
                );
            },
            disabled: disableQualify ? disablePublish : !disablePublish,
        },
        {
            icon: 'unpublish',
            handler: () => {
                handleDialogConfirmation(
                    'Unpublish',
                    `unpublish ${selected.length} top rated program${
                        selected.length === 1 ? '' : 's'
                    }`,
                    () => handleUnpublish(true)
                );
            },
            disabled: !disablePublish,
        },
    ];

    const columns = [
        {
            name: 'Client Name',
            key: 'clientName',
            id: 'client_name',
            format: 'bold',
            filter: {
                type: 'select',
                isMultiSelect: false,
                value: state.clientNameColumnFilter,
                options: clients || {},
                optionValue: 'id',
                optionKey: 'name',
                onChange: (client) => {
                    setState({
                        clientNameColumnFilter: client,
                    });
                },
            },
            urlKey: 'clientUrl',
            urlTarget: '_blank',
        },
        {
            id: 'listing_id',
            key: 'listingID',
            name: 'Listing ID',
            type: 'number',
            filter: {
                value: state.listingIDColumnFilter,
                onChange: (event) => {
                    const programID = parseInt(event.target.value) || 0;
                    const program = programs
                        .filter((obj) => obj.id === programID)
                        .map((obj) => {
                            return { value: obj.id, label: obj.name };
                        });
                    setState({
                        listingIDColumnFilter: programID,
                        programColumnFilter:
                            program.length > 0 ? program[0] : {},
                    });
                },
            },
        },
        {
            id: 'program_title',
            key: 'programTitle',
            name: 'Program Title',
            type: 'text',
            options: [],
            filter: {
                type: 'select',
                options: programs || {},
                optionValue: 'id',
                optionKey: 'name',
                value: state.programColumnFilter,
                onChange: (program) => {
                    console.log(program);
                    setState({
                        programColumnFilter: program,
                        listingIDColumnFilter: program.value,
                    });
                },
            },
        },

        {
            id: 'directory',
            key: 'directory',
            name: 'Directory',
            type: 'text',
            filter: {
                type: 'select',
                isMultiSelect: false,
                value: state.directoryColumnFilter,
                options: directories || {},
                optionValue: 'id',
                optionKey: 'name',
                onChange: (dir) => {
                    setState({ directoryColumnFilter: dir });
                },
            },
        },
        {
            id: 'country',
            key: 'country',
            name: 'Country',
            type: 'text',
            filter: {
                type: 'select',
                isMultiSelect: false,
                value: state.countryColumnFilter,
                options: countries || {},
                optionValue: 'id',
                optionKey: 'name',
                onChange: (cntry) => {
                    setState({ countryColumnFilter: cntry });
                },
            },
        },
        {
            id: 'year',
            key: 'year',
            name: 'Year',
            type: 'text',
            filter: {
                type: 'select',
                isMultiSelect: false,
                value: state.yearColumnFilter,
                options: years || {},
                optionValue: 'id',
                optionKey: 'name',
                onChange: (yr) => {
                    setState({
                        yearColumnFilter: yr,
                    });
                },
            },
        },
        {
            id: 'reviews_count',
            key: 'reviewsCount',
            name: 'Reviews Count',
            type: 'text',
        },
        {
            id: 'rating',
            key: 'rating',
            name: 'Average Rating',
            type: 'text',
        },
    ];

    const adjustImage = (iArray) => {
        var imageData = iArray.data;
        for (var i = 0; i < imageData.length; i += 4) {
            if (
                imageData[i] === 255 &&
                imageData[i + 1] === 0 &&
                imageData[i + 2] === 255
            ) {
                imageData[i + 3] = 0;
            }
        }
        return iArray;
    };

    useEffect(() => {
        // load font
        var WebFont = require('webfontloader');
        WebFont.load({
            google: {
                families: ['Poppins:bold'],
            },
        });
    }, []);

    const generateBigBadge = async (
        directory = '',
        country = '',
        year = ''
    ) => {
        const newDiv = document.createElement('canvas');
        newDiv.setAttribute('id', 'bigBadge');
        newDiv.setAttribute('width', '473px');
        newDiv.setAttribute('height', '436px');
        newDiv.style.display = 'none';
        const ctx = newDiv.getContext('2d');
        ctx.globalCompositeOperation = 'source-over';
        // create image element
        const paintedImage = await new Promise(function (resolve, reject) {
            let url = cloudinaryImage({
                filename: 'badge1.svg',
                path: 'createListing',
            });
            let img1 = new Image();
            img1.onload = () => resolve(img1);
            img1.crossOrigin = 'anonymous';
            img1.src = url;
        });
        ctx.drawImage(paintedImage, 0, 0);
        // set background to transparent
        var imgData = ctx.getImageData(0, 0, newDiv.width, newDiv.height);
        ctx.putImageData(adjustImage(imgData), 0, 0);
        ctx.textAlign = 'center';
        ctx.font = 'bold 28px Poppins';
        ctx.fillStyle = 'rgba(255, 255, 255, 0.90)';
        ctx.fillText(directory, newDiv.width / 2, 255);
        ctx.font = 'bold 28px Poppins';
        ctx.fillStyle = '#983535';
        ctx.fillText(country, newDiv.width / 2, 290);
        ctx.font = 'bold 32px Poppins';
        ctx.fillStyle = 'rgba(255, 255, 255, 0.90)';
        ctx.fillText(year, newDiv.width / 2, 350);
        document.body.appendChild(newDiv);
        var canvas = document.getElementById('bigBadge');
        var img = canvas.toDataURL('image/png');
        document.body.removeChild(newDiv);
        return img;
    };

    const generateSmallBadge = async (year = '') => {
        const newDiv = document.createElement('canvas');
        newDiv.setAttribute('id', 'smallBadge');
        newDiv.setAttribute('width', '473px');
        newDiv.setAttribute('height', '436px');
        newDiv.style.display = 'none';
        const ctx = newDiv.getContext('2d');
        ctx.globalCompositeOperation = 'source-over';
        // create image element
        const paintedImage = await new Promise((res) => {
            let url = cloudinaryImage({
                filename: 'small_badge.svg',
                path: 'createListing',
            });
            let img1 = new Image();
            img1.onload = () => res(img1);
            img1.crossOrigin = 'anonymous';
            img1.src = url;
        });
        ctx.drawImage(paintedImage, 0, 0);
        // set background to transparent
        var imgData = ctx.getImageData(0, 0, newDiv.width, newDiv.height);
        ctx.putImageData(adjustImage(imgData), 0, 0);
        ctx.textAlign = 'center';
        ctx.font = 'bold 32px Poppins';
        ctx.fillStyle = 'rgba(255, 255, 255, 0.90)';
        ctx.fillText(year, newDiv.width / 2, 350);
        document.body.appendChild(newDiv);
        var canvas = document.getElementById('smallBadge');
        var img = canvas.toDataURL('image/png');
        document.body.removeChild(newDiv);
        return img;
    };

    useEffect(() => {
        if (!!publishBadgesDialogOpen) {
            getQualifiedTRP();
            const month = today.toLocaleString('default', {
                month: 'long',
            });
            if (state.toPublish.length > 0) {
                handleOpenDialog(
                    `Publish Badges for the Month of ${month}`,
                    `Are you sure you want to publish ${state.toPublish.length} top rated badges? (${state.directoryName})`,
                    handlePublishCTA
                );
            } else {
                enqueueSnackbar(
                    `No Top Rated Programs to Publish for ${month} (${state.directoryName})`,
                    {
                        variant: 'error',
                        autoHideDuration: 2000,
                    }
                );
            }
            setGlobalState({ publishBadgesDialogOpen: false });
        }
        // eslint-disable-next-line
    }, [publishBadgesDialogOpen]);

    const handlePublishCTA = async () => {
        const data = new FormData();
        const badgeData = new FormData();
        var directory = '';
        handleCloseDialog();
        setTopRatedProgramsState({
            saving: true,
            linearProgressTitle: `Publishing...`,
        });
        state.toPublish.forEach(async (selectedProg) => {
            DIRECTORIES.forEach((d) => {
                if (selectedProg['search_criteria'][0].value === d.id) {
                    directory = d.name;
                }
            });
            await generateBigBadge(
                directory,
                selectedProg.country_name,
                selectedProg.year
            ).then((res) => {
                badgeData.append(selectedProg.id + '-big', dataURItoBlob(res));
            });
            await generateSmallBadge(selectedProg.year).then((res) => {
                badgeData.append(
                    selectedProg.id + '-small',
                    dataURItoBlob(res)
                );
            });
        });
        setTimeout(async () => {
            let selectedIds = state.toPublish.map((program) => program.id);
            const chunkSize = 3;
            for (let i = 0; i < selectedIds.length; i += chunkSize) {
                const chunk = selectedIds.slice(i, i + chunkSize);
                setTopRatedProgramsState({
                    linearProgressTitle: `Publishing ${i}/${selectedIds.length}`,
                });
                data.append('id', chunk.join(','));
                data.append('published', 1);
                chunk.forEach((id) => {
                    data.append(id + '-small', badgeData.get(id + '-small'));
                    data.append(id + '-big', badgeData.get(id + '-big'));
                });
                await postRequest(
                    '/programs/top-rated-programs/update',
                    data,
                    () => {
                        setState({ toPublish: [] });
                        setTopRatedProgramsState(
                            {
                                fetching: true,
                            },
                            fetchTopRatedPrograms()
                        );
                        enqueueSnackbar(
                            'Top Rated Programs have been updated!',
                            {
                                variant: 'success',
                                autoHideDuration: 2000,
                            }
                        );
                    },
                    (e) => {
                        console.log(e);
                        showError(
                            'Error saving your changes. Please try again.'
                        );
                    },
                    () => handlePublishCTA()
                );
                data.delete('id');
                data.delete('published');
                chunk.forEach((id) => {
                    data.delete(id + '-small');
                    data.delete(id + '-big');
                });
            }
            setTopRatedProgramsState(
                {
                    saving: false,
                },
                fetchTopRatedPrograms()
            );
        }, 1000);
    };

    const getQualifiedTRP = async () => {
        let dirParams =
            monthXdir[today.getMonth()] > 0
                ? `&directory_id=${monthXdir[today.getMonth()]}&limit=-1`
                : ``;
        await sendRequest(
            `/programs/top-rated-programs?year=${today.getFullYear()}&qualified=1&published=0${dirParams}`,
            (json) => {
                setState({
                    toPublish: json.top_rated_programs,
                });
            }
        );
    };

    useEffect(() => {
        let month = today.getMonth();
        let details = getDirectoryBy({
            key: 'id',
            val: [monthXdir[month] || 10],
        });
        setState({
            month,
            year: today.getFullYear(),
            thisMonthDirectory: monthXdir[month],
            directoryName: details[0].name,
        });
        getQualifiedTRP();
        // eslint-disable-next-line
    }, []);

    const handleDialogConfirmation = (batchAction, message, callback) => {
        let dialog = {
            show: true,
            title: batchAction,
            content: `Are you sure you want to ${message}?`,
            onOk: () => {
                callback();
            },
            onCancel: () => {
                handleCloseDialog();
            },
            stringOverride: {
                primaryAction: batchAction,
                secondaryAction: 'Cancel',
            },
        };
        setTopRatedProgramsState({
            dialog: dialog,
        });
    };

    const handleCloseDialog = () => {
        const dialog = {
            show: false,
            title: '',
            content: '',
            stringOverride: {},
            onOk: () => {},
            onClose: () => {},
        };
        setTopRatedProgramsState({ dialog: dialog });
        setGlobalState({ publishBadgesDialogOpen: false });
    };

    const icons = [
        {
            name: 'unpublished',
            icon: (
                <Tooltip title="Unpublished" placement="top">
                    <Typography className={classes.unpublished}>
                        UNPUBLISHED
                    </Typography>
                </Tooltip>
            ),
            placement: 'right',
            column: 'programTitle',
        },
        {
            name: 'unqualified',
            icon: (
                <Tooltip title="Unqualified" placement="top">
                    <Typography className={classes.unqualified}>
                        UNQUALIFIED
                    </Typography>
                </Tooltip>
            ),
            placement: 'right',
            column: 'programTitle',
        },
    ];

    const iconsChecker = (row, icons) => {
        return icons.filter((icon) => {
            if (icon.name === 'unpublished') return row.published === 0;
            if (icon.name === 'unqualified') return row.qualified === 0;
            return true;
        });
    };

    const handleQualify = (isBatch, topRatedProgram) => {
        const data = new FormData();

        handleCloseDialog();
        setTopRatedProgramsState({ saving: true });

        let selectedIds = isBatch
            ? selected.map((program) => program.id)
            : [topRatedProgram.id];

        data.append('id', selectedIds.join(','));
        data.append('qualified', 1);

        postRequest(
            '/programs/top-rated-programs/update',
            data,
            () => {
                setTopRatedProgramsState(
                    {
                        fetching: true,
                        saving: false,
                        selected: [],
                    },
                    fetchTopRatedPrograms()
                );
                enqueueSnackbar('Top Rated Programs have been updated!', {
                    variant: 'success',
                });
            },
            (error) => {
                console.log(error);
            },
            () => handleQualify(isBatch, topRatedProgram)
        );
    };

    const handleUnpublish = (isBatch, topRatedProgram) => {
        const data = new FormData();

        handleCloseDialog();
        setTopRatedProgramsState({
            saving: true,
            linearProgressTitle: 'Processing...',
        });

        let selectedIds = isBatch
            ? selected.map((program) => program.id)
            : [topRatedProgram.listingID];

        data.append('id', selectedIds.join(','));
        data.append('published', 0);

        postRequest(
            '/programs/top-rated-programs/update',
            data,
            () => {
                setTopRatedProgramsState(
                    {
                        fetching: true,
                        saving: false,
                        selected: [],
                    },
                    fetchTopRatedPrograms()
                );
                enqueueSnackbar('Top Rated Programs have been updated!', {
                    variant: 'success',
                });
            },
            (error) => {
                console.log(error);
            },
            () => handleUnpublish(isBatch, topRatedProgram)
        );
    };

    const handlePublish = async () => {
        const data = new FormData();
        const badgeData = new FormData();
        handleCloseDialog();
        setTopRatedProgramsState({
            saving: true,
            linearProgressTitle: `Publishing...`,
        });
        selected.forEach(async (selectedProg) => {
            await generateBigBadge(
                selectedProg.directory,
                selectedProg.countryDetails?.name ?? '',
                selectedProg.year
            ).then((res) => {
                badgeData.append(selectedProg.id + '-big', dataURItoBlob(res));
            });
            await generateSmallBadge(selectedProg.year).then((res) => {
                badgeData.append(
                    selectedProg.id + '-small',
                    dataURItoBlob(res)
                );
            });
        });
        setTimeout(async () => {
            if (selected.length * 2 === Array.from(badgeData.keys()).length) {
                let selectedIds = selected.map((program) => program.id);
                const chunkSize = 3;
                for (let i = 0; i < selectedIds.length; i += chunkSize) {
                    const chunk = selectedIds.slice(i, i + chunkSize);
                    setTopRatedProgramsState({
                        linearProgressTitle: `Publishing ${i}/${selectedIds.length}`,
                    });
                    data.append('id', chunk.join(','));
                    data.append('published', 1);
                    chunk.forEach((id) => {
                        data.append(
                            id + '-small',
                            badgeData.get(id + '-small')
                        );
                        data.append(id + '-big', badgeData.get(id + '-big'));
                    });
                    await postRequest(
                        '/programs/top-rated-programs/update',
                        data,
                        () => {
                            setTopRatedProgramsState(
                                {
                                    fetching: true,
                                    selected: [],
                                },
                                fetchTopRatedPrograms()
                            );
                            enqueueSnackbar(
                                'Top Rated Programs have been updated!',
                                {
                                    variant: 'success',
                                    autoHideDuration: 2000,
                                }
                            );
                        },
                        (error) => {
                            console.log(error);
                        },
                        () => handlePublish()
                    );
                    data.delete('id');
                    data.delete('published');
                    chunk.forEach((id) => {
                        data.delete(id + '-small');
                        data.delete(id + '-big');
                    });
                }
                setTopRatedProgramsState(
                    {
                        saving: false,
                    },
                    fetchTopRatedPrograms()
                );
            }
        }, 1000);
    };

    const handleUnqualify = (isBatch, topRatedProgram) => {
        const data = new FormData();
        handleCloseDialog();
        setTopRatedProgramsState({
            saving: true,
            linearProgressTitle: 'Processing...',
        });
        let selectedIds = isBatch
            ? selected.map((program) => program.id)
            : [topRatedProgram.id];
        data.append('id', selectedIds.join(','));
        data.append('qualified', 0);
        let dir = [];
        isBatch
            ? // eslint-disable-next-line
              selected.forEach((program) => {
                  // eslint-disable-next-line
                  DIRECTORIES.forEach((d) => {
                      if (String(program.directory) === String(d.name)) {
                          dir.push({
                              listingID: program.listingID,
                              directoryID: d.id,
                          });
                      }
                  });
              })
            : // eslint-disable-next-line
              DIRECTORIES.forEach((d) => {
                  if (String(topRatedProgram.directory) === String(d.name)) {
                      dir.push({
                          listingID: topRatedProgram.listingID,
                          directoryID: d.id,
                      });
                  }
              });
        postRequest(
            '/programs/top-rated-programs/update',
            data,
            () => {
                setTopRatedProgramsState(
                    {
                        fetching: true,
                        saving: false,
                        selected: [],
                    },
                    fetchTopRatedPrograms()
                );
                enqueueSnackbar('Top Rated Programs have been updated!', {
                    variant: 'success',
                    autoHideDuration: 2000,
                });
            },
            (error) => {
                console.log(error);
            },
            () => handleUnqualify(isBatch, topRatedProgram)
        );
    };

    const handleSelectMultiplePrograms = (rows) => {
        let disablePublish = !rows.every((val) => val.published === 0);
        let disableQualify = !rows.every((val) => val.qualified === 0);

        setTopRatedProgramsState({
            selected: rows,
            disablePublish: disablePublish,
            disableQualify: disableQualify,
        });
    };

    const handleOpenDialog = (title, content, callback) => {
        const dialog = {
            actions: 'OkCancel',
            title,
            content,
            show: true,
            onOk: () => {
                callback();
            },
            onCancel: () => {
                handleCloseDialog();
            },
        };
        setTopRatedProgramsState({ dialog: dialog });
    };

    const rowMenusChecker = (row, menus) =>
        menus.filter((menu) => {
            if (menu.name === 'view') {
                let baseurl = GA_URL.endsWith('/')
                    ? GA_URL.slice(0, -1)
                    : GA_URL;
                menu.url = `${baseurl}/providers/${row.provider_alias}/programs/${row.program_alias}-${row.listingID}`;
            }
            if (menu.name === 'unqualify') {
                return row.qualified === 1 && row.published === 0;
            }
            if (menu.name === 'qualify') {
                return row.qualified === 0;
            }
            if (menu.name === 'manage') {
            }
            if (menu.name === 'view-sr') {
                menu.url = getSRPage(row);
            }
            return menu;
        });

    const handleBatchDialogConfirmation = (
        batchAction,
        message,
        callback,
        content = null
    ) => {
        let dialog = {
            show: true,
            title: batchAction,
            content:
                content ||
                `Are you sure you want to set selected article/s to ${message}?`,
            onOk: () => {
                callback();
            },
            onCancel: () => {
                handleCloseDialog();
            },
            stringOverride: {
                primaryAction: batchAction,
                secondaryAction: 'Cancel',
            },
        };

        setTopRatedProgramsState({
            dialog: dialog,
        });
    };

    const getSRPage = (row) => {
        const { directoryID, countryDetails } = row;
        let url;
        let directoryDetails = getDirectoryBy({
            key: 'id',
            val: [directoryID || 10],
        });
        let countryAlias = countryDetails?.alias;
        url = [
            trimSlash(GA_URL),
            `${directoryDetails[0].alias}`,
            'search',
            `${countryAlias}`,
            `${directoryDetails[0].page_number_tag}-1`,
        ].join('/');
        return url;
    };

    const getData = () => {
        return data;
    };

    const handleClearGlobalFilters = () => {
        delete filters.is_qualified;
        delete filters.is_primary_listing;
        delete filters.is_published;
        setTopRatedProgramsState({ globalFilters: {}, page: 0 });
    };

    const handleApplyGlobalFilters = (globalFilter) => {
        if (!(JSON.stringify(globalFilters) === JSON.stringify(globalFilter)))
            setTopRatedProgramsState({
                page: 0,
                pages: 0,
                data: [],
                globalFilters: globalFilter,
            });
    };

    const handleCloseGlobalFilters = () => {
        setTopRatedProgramsState({ globalFilterOpen: false });
    };

    const handleApplyColumnFilters = () => {
        const {
            directoryColumnFilter,
            yearColumnFilter,
            listingIDColumnFilter,
            programColumnFilter,
            clientNameColumnFilter,
            countryColumnFilter,
        } = state;

        let newFilters = {};
        directoryColumnFilter.value &&
            (newFilters.directory = directoryColumnFilter.value);
        yearColumnFilter.value && (newFilters.year = yearColumnFilter.value);
        listingIDColumnFilter.value &&
            (newFilters.programId = listingIDColumnFilter);
        clientNameColumnFilter.value &&
            (newFilters.client = clientNameColumnFilter.value);
        countryColumnFilter.value &&
            (newFilters.country = countryColumnFilter.value);
        if (
            !!programColumnFilter.value ||
            JSON.stringify(listingIDColumnFilter) !== '{}'
        )
            newFilters.program = programColumnFilter.value
                ? programColumnFilter.value
                : listingIDColumnFilter;

        setTopRatedProgramsState({ columnFilters: newFilters, page: 0 });
    };

    const handleClearColumnFilters = () => {
        setState({
            yearColumnFilter: {},
            clientNameColumnFilter: {},
            listingIDColumnFilter: {},
            programColumnFilter: {},
            directoryColumnFilter: {},
            countryColumnFilter: {},
        });
        setTopRatedProgramsState({ columnFilters: {} });
    };
    const rowClasses = [{ className: classes.row }];

    const activeGlobalFilters = intersection(keys(globalFilters), [
        'is_primary_listing',
        'is_qualified',
        'is_published',
    ]).some((key) => ![null, undefined, ''].includes(globalFilters[key]));

    return (
        <>
            <CustomGlobalFilterDrawer
                isTopRatedProgram={true}
                open={globalFilterOpen}
                onClose={handleCloseGlobalFilters}
                onClear={handleClearGlobalFilters}
                onApply={handleApplyGlobalFilters}
                filters={[
                    {
                        key: 'is_primary_listing',
                        type: 'switch',
                        filterLabel: 'Primary Listing',
                        options: [
                            {
                                value: '0',
                                label: 'No',
                            },
                            {
                                value: '1',
                                label: 'Yes',
                            },
                        ],
                    },
                    {
                        key: 'is_qualified',
                        type: 'switch',
                        filterLabel: 'Qualified',
                        options: [
                            {
                                value: '0',
                                label: 'No',
                            },
                            {
                                value: '1',
                                label: 'Yes',
                            },
                        ],
                    },
                    {
                        key: 'is_published',
                        type: 'switch',
                        filterLabel: 'Published',
                        options: [
                            {
                                value: '0',
                                label: 'No',
                            },
                            {
                                value: '1',
                                label: 'Yes',
                            },
                        ],
                    },
                ]}
            />
            <ResultsTable
                batchActions={batchActions}
                data={getData()}
                columns={columns}
                count={count}
                rowMenus={rowMenus}
                rowMenusChecker={rowMenusChecker}
                rowsPerPage={rowsPerPage}
                page={page}
                hasSearch={false}
                isLoadingData={fetching}
                hasGlobalFilters={true}
                hasColumnFilters={true}
                icons={icons}
                selected={selected}
                iconsChecker={iconsChecker}
                label={count === 1 ? 'top rated program' : 'top rated programs'}
                onColumnFiltersApply={handleApplyColumnFilters}
                onColumnFilterClose={handleClearColumnFilters}
                activeGlobalFilters={activeGlobalFilters}
                onRowsPerPageChange={(rows) => {
                    setTopRatedProgramsState({
                        page: 0,
                        rowsPerPage: rows,
                    });
                }}
                onGlobalFiltersClick={() =>
                    setTopRatedProgramsState({ globalFilterOpen: true })
                }
                onSelectedChange={(rows) => handleSelectMultiplePrograms(rows)}
                onPageChange={(page) =>
                    setTopRatedProgramsState({ page: page })
                }
                rowClasses={rowClasses}
            />
            <DialogBox
                actions="OkCancel"
                title={dialog.title.toUpperCase()}
                contentText={dialog.content}
                open={dialog.show}
                onOk={dialog.onOk}
                onCancel={dialog.onCancel}
                onClose={dialog.onCancel}
                stringOverride={dialog.stringOverride}
            />
            <DialogBox open={saving} title={linearProgressTitle} actions="">
                <LinearProgress />
            </DialogBox>
        </>
    );
}
