import React from 'react';

export const GlobalContext = React.createContext();
export const AccountsContext = React.createContext();
export const InvoicesContext = React.createContext();
export const AdsContext = React.createContext();
export const ReviewsContext = React.createContext();
export const ProgramsContext = React.createContext();
export const CommLogsContext = React.createContext();
export const IndividualAccountContext = React.createContext();
export const UserManagementContext = React.createContext();
export const DashboardContext = React.createContext();
export const AccountStatsContext = React.createContext();
export const InquiryContext = React.createContext();
export const InquiryDrawerContext = React.createContext();
export const ResponseTimeContext = React.createContext();
export const CollectionsContext = React.createContext();
export const ArticlesContext = React.createContext();
export const CMSContext = React.createContext();
export const AuthorContext = React.createContext();
export const TopicContext = React.createContext();
export const MediaToolbarContext = React.createContext();
export const GuidesContext = React.createContext();
export const ScholarshipContext = React.createContext();
export const DealContext = React.createContext();
export const ApplicationContext = React.createContext();
export const UsersContext = React.createContext();
export const ScholarshipRankingContext = React.createContext();
export const InterviewsContext = React.createContext();
export const InterviewCMSContext = React.createContext();
export const SalesAndCollectionsSummaryContext = React.createContext();
export const UrlVerifierContext = React.createContext();
export const ProviderUrlVerifierContext = React.createContext();
export const ProgramUrlVerifierContext = React.createContext();
export const AdUrlVerifierContext = React.createContext();
export const TravelResourceRankingContext = React.createContext();
export const ProgramRankingContext = React.createContext();
export const DefaultMetaContext = React.createContext();
export const CustomMetaContext = React.createContext();
export const TopRatedProgramsContext = React.createContext();

//Create Tool Context
export const CreateEditAdContext = React.createContext();
export const AddEditInvoiceContext = React.createContext();
export const AddEditProgramContext = React.createContext();
export const EditGuideContext = React.createContext();
export const EditFAQContext = React.createContext();
export const AddEditInteviewContext = React.createContext();
export const SEOToolContext = React.createContext();
export const ReviewWidgetGeneratorContext = React.createContext();
