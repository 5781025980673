import React, { useEffect, useState } from 'react';
import {
    Drawer,
    makeStyles,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Button,
} from '@material-ui/core';
import FilterControl from './FilterControl';
import { useObjectState } from '../../utilities/customHooks';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > div:not(#closeButton)': {
            borderLeft: '1px solid #ddd',
            display: 'flex',
            flexGrow: 1,
            padding: `24px 16px`,
        },
        '& > #closeButton': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: `16px 24px`,
        },
    },
    teamDropdown: {
        maxWidth: 200,
    },
    listingTypeDropdown: {
        width: 100,
    },
    wrapper: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    globalFilterCTA: {
        display: 'grid !important',
        '& > button': {
            margin: '4px 0px',
        },
    },
}));

const CustomGlobalFilterDrawer = ({
    open,
    filters = [],
    onApply = () => {},
    onClear = () => {},
    onClose = () => {},
    isLoading,
    closeOnApply,
    closeOnClear,
    exclude_goabroad,
    isTopRatedProgram,
}) => {
    useEffect(()=>
        isTopRatedProgram && 
            setFilterValues({
                is_published: 0, 
                is_qualified: 1
        // eslint-disable-next-line
    }),[]);
    const classes = useStyles();
    const [filterValues, setFilterValues] = useState({});
    const [state, setState] = useObjectState({
        checked: true,
        value: '1',
        first: true,
    });

    const getDefault = (key) => {
        const [{ options: [{ value } = {}] = [] }] = filters.filter(
            (filter) => filter.key === key
        );
        return value;
    };

    const handleFilterToggle = (key, enabled) => {
        setState({ first: false, value: '0', checked: null });
        setFilterValues((prev) => {
            const { [key]: filter, ...others } = prev;
            if (enabled) {
                return { ...others, [key]: getDefault(key) };
            } else {
                return { ...others };
            }
        });
    };

    const handleFilterValueChange = (value, key) =>
        setFilterValues((prev) => ({ ...prev, [key]: value }));

    const handleApply = () => {
        onApply(filterValues);
        if (closeOnApply) onClose();
    };

    const handleClear = () => {
        onClear(filterValues);
        setFilterValues({});
        if (closeOnClear) onClose();
    };

    return (
        <Drawer open={open} anchor="bottom" onClose={onClose}>
            <div className={classes.root}>
                {[...filters].map((filter) => {
                    const { key, options, type, filterLabel, optionsLabel } =
                        filter;
                    let isChecked = filterValues.hasOwnProperty(key);

                    return (
                        <div className={classes.wrapper}>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            data-cy="checkbox-publication"
                                            color="primary"
                                            value="accountType"
                                            checked={
                                                state.first && exclude_goabroad
                                                    ? state.checked
                                                    : isChecked
                                            }
                                            onChange={(e, checked) =>
                                                handleFilterToggle(key, checked)
                                            }
                                        />
                                    }
                                    label={filterLabel || 'Unlabeled Filter'}
                                />
                            </FormGroup>
                            <FormGroup row>
                                <FilterControl
                                    value={
                                        state.first && exclude_goabroad
                                            ? state.value
                                            : filterValues[key]
                                    }
                                    filterKey={key}
                                    onChange={handleFilterValueChange}
                                    options={options}
                                    optionsLabel={optionsLabel}
                                    type={type}
                                    enabled={
                                        ![null, undefined].includes(
                                            filterValues[key]
                                        )
                                    }
                                />
                            </FormGroup>
                        </div>
                    );
                })}
                <div id="closeButton" className={classes.globalFilterCTA}>
                    <Button
                        data-cy="apply-global"
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={handleApply}
                        disabled={isLoading}
                    >
                        Apply
                    </Button>
                    <Button
                        id="clear-button"
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={handleClear}
                        disabled={isLoading}
                    >
                        Clear
                    </Button>
                </div>
            </div>
        </Drawer>
    );
};

export default CustomGlobalFilterDrawer;
