import {
    Checkbox,
    Drawer,
    FormControlLabel,
    Paper,
    Typography,
    Divider,
    Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTopRatedProgramsState } from './TopRatedProgramsProvider';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Check';

import SearchBasedDropdown from '../../../components/SearchBasedDropdown/SearchBasedDropdown';
import { useObjectState } from '../../../utilities/customHooks';
import { DIRECTORIES } from '../../../utilities/directory';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        width: 420,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '& .react-datepicker-wrapper': {
            display: 'flex',

            '& input': {
                padding: theme.spacing(1),
                border: '1px solid #ccc',
                borderRadius: 3,
                height: 36,
            },
        },
    },
    paper: {
        padding: theme.spacing(2),
    },
    counter: {
        alignSelf: 'end',
    },
    cta: {
        display: 'flex',
        marginTop: 'auto',
        '& button:first-child': {
            marginRight: theme.spacing(2),
        },
    },
    datePicker: {
        display: 'flex',
    },
    checkbox: {
        width: '100%',
    },
}));

export default function DataFiltersDrawer() {
    const classes = useStyles();
    const [state, setState] = useObjectState({
        directory: null,
        // online: false,
        country: null,
    });

    const directories = [...DIRECTORIES].filter(
        (directory) => !!directory.page_number_tag || directory.abbrv === 'GA'
    );
    const {
        countries,
        dataFilterOpen,
        drawerFilters,
        setTopRatedProgramsState,
    } = useTopRatedProgramsState();

    const handleFieldChange = (field) => (value) => {
        setState({ [field]: value });
        if (field === 'directory') {
            setState({ directory: value });
        } else if (field === 'country') {
            setState({ country: value });
        }
    };

    useEffect(() => {
        setState({
            directory: drawerFilters.directory,
            country: drawerFilters.country,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerFilters]);

    const handleOnlineChange = (e, checked) => setState({ online: checked });

    const handleApplyFilters = () => {
        let drawerFilters = {};
        state.directory && (drawerFilters.directory = state.directory);
        // state.online && (drawerFilters.online = state.online);
        state.country && (drawerFilters.country = state.country);
        setTopRatedProgramsState({
            drawerFilters: drawerFilters,
            dataFilterOpen: false,
            page: 0,
            count: 0,
        });
    };

    const handleClearFilters = () => {
        setTopRatedProgramsState({
            drawerFilters: {},
            dataFilterOpen: false,
            page: 0,
        });
        setState({ directory: null, country: null });
    };

    return (
        <Drawer
            open={dataFilterOpen}
            onClose={() => setTopRatedProgramsState({ dataFilterOpen: false })}
            anchor="right"
        >
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Typography variant="h6">Search Filters</Typography>
                    <br />
                    <div>
                        <Typography variant="overline">Directory</Typography>
                        <SearchBasedDropdown
                            arrayOptions={directories}
                            labelKey="name"
                            valueKey="id"
                            value={state.directory}
                            placeholder="Select Directory"
                            onChange={handleFieldChange('directory')}
                            isClearable
                        />
                        <br />
                        <br />
                        <Typography variant="overline">Country</Typography>
                        <br />
                        <SearchBasedDropdown
                            arrayOptions={countries}
                            labelKey="name"
                            valueKey="id"
                            value={state.country}
                            placeholder="Select Country"
                            onChange={handleFieldChange('country')}
                            isClearable
                        />

                        <Divider />
                        <br />
                        <Typography variant="overline">Online</Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.online}
                                    onChange={handleOnlineChange}
                                    name="online"
                                    color="primary"
                                />
                            }
                            label={'Include Online'}
                            className={classes.checkbox}
                        />
                    </div>
                </Paper>
                <div className={classes.cta}>
                    <Button
                        fullWidth
                        color="secondary"
                        variant="contained"
                        disableElevation
                        disableFocusRipple
                        startIcon={<CloseIcon fontSize="small" />}
                        onClick={handleClearFilters}
                    >
                        Clear Filters
                    </Button>
                    <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        disableFocusRipple
                        disableElevation
                        startIcon={<AddIcon fontSize="small" />}
                        onClick={handleApplyFilters}
                    >
                        Apply & Search
                    </Button>
                </div>
            </div>
        </Drawer>
    );
}
