import React, { useContext, useEffect, useMemo } from 'react';
import { TopRatedProgramsContext } from '../../../context';
import { useObjectState } from '../../../utilities/customHooks';
import { sendRequest } from '../../../helpers/apiRequestUtility';
import { useGlobalMethods, useGlobalState } from '../../Client/GlobalProvider';
import getDirectoryBy, { DIRECTORIES } from '../../../utilities/directory';
import { exportCsv } from '../../../helpers/apiRequestUtility';

const { Provider } = TopRatedProgramsContext;

export default function TopRatedProgramsProvider(props) {
    const { countries, fetchingProviders, providers, setGlobalState } =
        useGlobalState();
    const { fetchCountries, fetchProviders } = useGlobalMethods();
    const [state, setState] = useObjectState({
        count: 0,
        countries: {},
        client: {},
        clients: {},
        columnFilters: {},
        drawerFilters: {},
        globalFilters: { is_qualified: 1, is_published: '0' },
        data: [],
        dataFilterOpen: false,
        dialog: {
            show: false,
            title: '',
            content: '',
            stringOverride: {},
            onOk: () => {},
            onCancel: () => {},
        },
        directories: {},
        directoryToPublish: 0,
        filters: {},
        fetching: true,
        globalFilterOpen: false,
        page: 0,
        programs: {},
        program_title: '',
        publishing: false,
        rowsPerPage: 10,
        topBadgeByMonth: [],
        disablePublish: false,
        disableQualify: false,
        selected: [],
        saving: false,
        linearProgressTitle: '',
    });

    const { rowsPerPage, page, drawerFilters, columnFilters, globalFilters } =
        state;

    useEffect(() => {
        async function asyncFetch() {
            let dir = [...DIRECTORIES].filter(
                (directory) =>
                    !!directory.page_number_tag || directory.abbrv === 'GA'
            );
            setState({ directories: dir });

            await fetchCountries();
            await fetchProviders();
            await fetchProgramNames();
        }
        asyncFetch();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchTopRatedPrograms();
        // eslint-disable-next-line
    }, [drawerFilters, columnFilters, globalFilters, rowsPerPage, page]);

    useMemo(() => {
        setState({ clients: providers });
        // eslint-disable-next-line
    }, [fetchingProviders]);

    useEffect(() => {
        setState({ countries: countries });
        // eslint-disable-next-line
    }, [countries]);

    const fetchProgramNames = async () => {
        setState({ fetching: true });
        await sendRequest(
            `/programs?limit=-1&fields=name,provider_id,id&sort=+name`,
            (json) => {
                setState({ programs: json.programs, fetching: false });
            }
        );
    };

    const getColumnFilters = () => {
        let filter = '';
        const { program, year, country, directory, client } = columnFilters;

        if (JSON.stringify(columnFilters) === '{}') return filter;
        filter += program ? `&program_id=${program}` : ``;
        filter += year ? `&year=${year}` : ``;
        filter += country ? `&country_id=${country}` : ``;
        filter += directory ? `&directory_id=${directory}` : ``;
        filter += client ? `&provider_id=${client}` : ``;

        return filter;
    };

    const getDrawerFilters = () => {
        let filter = '';
        const { directory, country } = state.drawerFilters;
        filter += directory ? `&directory_id=${directory.value}` : ``;
        filter += country ? `&country_id=${country.value}` : ``;

        return filter;
    };

    const getGlobalFilters = () => {
        let filter = '';
        const { is_qualified, is_published, is_primary_listing } =
            globalFilters;

        filter += is_qualified ? `&qualified=${is_qualified}` : ``;
        filter += is_published ? `&published=${is_published}` : ``;
        filter += is_primary_listing ? `&primary=${is_primary_listing}` : ``;

        return filter;
    };

    const fetchTopRatedPrograms = async () => {
        let columnFilterStr = getColumnFilters();
        let drawerFilterStr = getDrawerFilters();
        let globalFilterStr = getGlobalFilters();

        setState({ fetching: true });

        let params = `limit=${rowsPerPage}&offset=${
            page * rowsPerPage
        }${columnFilterStr}${drawerFilterStr}${globalFilterStr}`;

        await sendRequest(`/programs/top-rated-programs?${params}`, (json) => {
            let data = json['top_rated_programs'];
            let rows = [];
            data.forEach((program) => {
                let providerID = program.provider_id;
                let clientName = program.provider_name;
                let listingID = program.program_id;
                let programTitle = program.program_title;
                let year = program.year;
                let qualified = program.qualified;
                let primary = program.primary;
                let published = program.published;
                let rating = program.rating.toFixed(2);
                let reviewsCount = program.reviews_count;
                let id = program.id;
                let directoryID = program.search_criteria.find((sc) => {
                    return sc.criteria === 'directory_id';
                }).value;
                let instnameURL = program.provider_alias;
                let countryName = program.country_name;
                let countryID = program.search_criteria.find((sc) => {
                    return sc.criteria === 'country_id';
                }).value;
                let directory = getDirectoryBy({
                    key: 'id',
                    val: [directoryID || 10],
                })[0].name;

                let country = program.country_name;
                let countryDetails = countries.find((c) => c.id === countryID);

                let clientUrl = `/client/profile/${providerID}/`;

                const row = {
                    id,
                    clientName,
                    listingID,
                    programTitle,
                    directory,
                    country,
                    year,
                    qualified,
                    primary,
                    published,
                    rating,
                    reviewsCount,
                    directoryID,
                    instnameURL,
                    countryName,
                    countryDetails,
                    providerID,
                    clientUrl,
                };
                rows.push(row);
            });

            setState({
                data: rows,
                fetching: false,
                count: json['meta'].count,
            });
        }).finally(() => {
            setState({
                fetching: false,
            });
        });
    };

    const handleExportTopRatedPrograms = () => {
        let selectedIds = state.selected.map((program) => program.id);
        let columnFilterStr = getColumnFilters();
        let drawerFilterStr = getDrawerFilters();
        let globalFilterStr = getGlobalFilters();

        let url =
            '/programs/top-rated-programs/export?id=' +
            selectedIds.join(',') +
            columnFilterStr +
            drawerFilterStr +
            globalFilterStr;

        handleCloseDialog();
        setState({
            saving: true,
            linearProgressTitle: 'Processing...',
        });
        exportCsv(url, 'TopRatedPrograms.csv', () => {
            setState({ saving: false, selected: [] });
        });
    };

    const handleCloseDialog = () => {
        const dialog = {
            show: false,
            title: '',
            content: '',
            stringOverride: {},
            onOk: () => {},
            onClose: () => {},
        };
        setState({ dialog: dialog });
        setGlobalState({ publishBadgesDialogOpen: false });
    };

    return (
        <Provider
            value={{
                state,
                setTopRatedProgramsState: setState,
                handleExportTopRatedPrograms,
                fetchTopRatedPrograms,
            }}
        >
            {props.children}
        </Provider>
    );
}

export const useTopRatedProgramsState = () => {
    const { state, setTopRatedProgramsState } = useContext(
        TopRatedProgramsContext
    );
    return { ...state, setTopRatedProgramsState };
};

export const useTopRatedProgramsMethods = () => {
    const { state, ...methods } = useContext(TopRatedProgramsContext);
    return methods;
};
